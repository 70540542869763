<template>
  <span
    v-show="text || images"
    class="ok-tooltip-container"
  >
    <span ref="icon">
      <slot>
        <feather-icon
          v-if="!target"
          icon="AlertOctagonIcon"
          class="cursor-pointer ok-tooltip-icon"
        />
      </slot>
    </span>
    <b-tooltip
      :target="() => target || $refs.icon"
      :title="text"
      :placement="placement"
      :custom-class="`ok-tooltip ${images ? 'has-images' : ''} ${customClass}`"
    >
      <div :class="{'mb-1': !!images}">
        {{ text }}
      </div>
      <div class="images-list d-flex justify-content-center align-items-start px-1">
        <template v-if="images">
          <template v-for="(image, idx) in imagesList">
            <div :key="idx">
              <img
                v-if="isString(image.src)"
                :src="image.src"
                :style="{maxWidth: images.length > 1 ? '150px' : '250px'}"
                class="image-contain mb-1"
                alt="tip image"
              >
              <component
                :is="image.src"
                v-else
              />
              <div
                v-if="image.title"
                class="image-title"
              >
                {{ image.title && $te(image.title) ? $t(image.title) : image.title }}
              </div>
            </div>
          </template>
        </template>
      </div>
    </b-tooltip>
  </span>
</template>

<script>
import { BTooltip } from 'bootstrap-vue'

export default {
  name: 'OkTooltip',
  components: {
    BTooltip,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    images: {
      type: Array,
      default: null,
    },
    target: {
      default: null,
    },
    customClass: {
      type: String,
      default: '',
    },
    placement: {
      type: String,
      default: 'top',
    },
  },
  computed: {
    imagesList() {
      if (!this.images) return null
      return this.images.map(image => (image?.src ? image : { src: image, title: '' }))
    },
  },
  methods: {
    isString(image) {
      return typeof image === 'string'
    },
  },
}
</script>

<style lang="scss">
.ok-tooltip {
  &.has-images {
    .tooltip-inner {
      max-width: 100vw!important;
    }
  }

  .images-list {
    & > div {
      margin-right: 7px;
    }

    .image-title {
      margin-top: 5px;
      font-size: 11px;
    }
  }

  &.large-assets {
    img {
      max-width: 170px!important;
    }
  }

  &.medium-assets {
    img {
      max-width: 100px!important;
    }
  }

  &.small-assets {
    img {
      max-width: 100px!important;
    }
  }

  &.light-tooltip {
    .tooltip-inner {
      color: var(--theme-dark-body-bg);
      background: #FFFFFF;
    }
    &.bs-tooltip-left, &.bs-tooltip-right {
      .arrow::before {
        border-left-color: #FFFFFF!important;
        border-right-color: #FFFFFF!important;
      }
    }
  }
}
</style>

<template>
  <div
    :class="(fromComponent?'from-component':'')"
    class="ok-form-input"
    @click="$emit('click')"
  >
    <validation-provider
      #default="{ errors, invalid }"
      :name="validationName || label"
      :rules="inputRules"
    >
      <b-form-group
        :class="{'mb-0': hideErrors || showCounter}"
        :label="hideLabel ? '' : label"
        :label-class="{'p-0': hideLabel, [labelClass]: true}"
        :label-cols="labelCols"
        :label-cols-lg="labelColsLg"
        :label-cols-md="labelColsMd"
        :label-cols-sm="labelColsSm"
        :label-cols-xl="labelColsXl"
        :state="errors.length ? false : null"
      >
        <template #label>
          <div
            v-if="!hideLabel"
            class="d-flex ok-input-label"
          >
            <div class="one-line">
              <slot name="label">
                {{ hideLabel ? '' : label }}
              </slot>
            </div>

            <ok-tooltip
              :custom-class="tooltipClass"
              :images="tooltipImages"
              :text="tooltip"
            />

            <span
              v-if="isRequired && !hideRequiredAsterisk && !hideLabel"
              class="text-danger required-star"
            >
              *
            </span>
          </div>
        </template>
        <slot
          :errors="errors"
          :invalid="invalid"
        >
          <b-input-group
            :append="append"
            :class="{'input-group-merge': isAppendMerged}"
          >
            <b-form-textarea
              v-if="textarea"
              ref="input"
              :disabled="disabled"
              :placeholder="inputPlaceholder"
              :readonly="readOnly"
              :rows="rows"
              :state="errors.length > 0 ? false : null"
              :style="styleObj"
              :value="value"
              @input="parseArabic($event)"
            />
            <b-form-input
              v-else
              :class="inputClass"
              :dir="dir"
              :disabled="disabled"
              :placeholder="inputPlaceholder"
              :readonly="readOnly"
              :size="size"
              :state="errors.length > 0 ? false : null"
              :value="value"
              @input="parseArabic($event)"
            />

            <b-input-group-append
              v-if="showAppend"
              :is-text="isAppendText"
            >
              <slot name="append" />
            </b-input-group-append>
          </b-input-group>
          <slot name="footer" />
        </slot>
        <small
          v-if="!hideErrors"
          class="text-danger"
        >
          {{ errors[0] }}
        </small>
      </b-form-group>
    </validation-provider>
  </div>
</template>

<script>
import {
  BFormGroup, BFormInput, BFormTextarea, BInputGroup, BInputGroupAppend, VBTooltip,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import '@core/utils/validations/validations'
import isPlainObject from 'lodash/isPlainObject'
import okTextInputProps from '@/@bya3/components/inputs/utils/ok-text-input-props'
import OkTooltip from '@/@bya3/components/utils/OkTooltip'
import { parseArabic } from '@/utils/helpers'

export default {
  name: 'OkTextInput',
  components: {
    OkTooltip,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    BInputGroup,
    BInputGroupAppend,
    BFormTextarea,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: okTextInputProps,
  computed: {
    inputRules() {
      if (!this.isRequired) return this.rules

      if (isPlainObject(this.rules)) {
        return {
          ...this.rules,
          required: true,
        }
      }

      return `required${this.isRequired && this.rules ? '|' : ''}${this.rules || ''}`
    },
    showAppend() {
      return !!this.$scopedSlots.append || !!this.$slots.append
    },
    inputPlaceholder() {
      return this.placeholder ?? this.label
    },
  },
  methods: {
    parseArabic(e) {
      let str = e
      if ((isPlainObject(this.rules) && this.rules?.positive) || this.rules.includes('positive')) {
        str = parseArabic(e)
      }
      this.$emit('input', str)
    },
  },
}
</script>

<style lang="scss">
.ok-form-input {
  &:not(.from-component) legend {
    margin: 0;
  }

  legend {
    .required-star {
      padding-left: 3px;
    }

    .feather {
      margin-left: 3px;
    }

    [dir=rtl] & {
      .required-star {
        padding-right: 3px;
        padding-left: 0;
      }

      .feather {
        margin-left: 0;
        margin-right: 3px;
      }
    }
  }
}
</style>

const okTextInputProps = {
  value: {
    type: [String, Number, Boolean, Array],
    default: '',
  },
  validationName: {
    type: String,
    default: '',
  },
  label: {
    default: '',
  },
  placeholder: {
    type: String,
    default: null,
  },
  isRequired: {
    type: Boolean,
    default: false,
  },
  rules: {
    type: [String, Object],
    default: '',
  },
  size: {
    type: String,
    default: '',
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  tooltip: {
    type: String,
    default: '',
  },
  tooltipClass: {
    type: String,
    default: '',
  },
  tooltipImages: {
    type: Array,
    default: null,
  },
  hideRequiredAsterisk: {
    type: Boolean,
    default: false,
  },
  hideLabel: {
    type: Boolean,
    default: false,
  },
  textarea: {
    type: Boolean,
    default: false,
  },
  hideErrors: {
    type: Boolean,
    default: false,
  },
  showCounter: {
    type: Boolean,
    default: false,
  },
  labelColsXl: {
    type: [Number, String],
    default: null,
  },
  labelColsLg: {
    type: [Number, String],
    default: null,
  },
  labelColsMd: {
    type: [Number, String],
    default: null,
  },
  labelColsSm: {
    type: [Number, String],
    default: null,
  },
  labelCols: {
    type: [Number, String],
    default: null,
  },
  append: {
    type: String,
    default: '',
  },
  isAppendText: {
    type: Boolean,
    default: false,
  },
  isAppendMerged: {
    type: Boolean,
    default: false,
  },
  labelClass: {
    type: String,
    default: '',
  },
  inputClass: {
    type: String,
    default: '',
  },
  fromComponent: {
    type: Boolean,
    default: false,
  },
  dir: {
    type: String,
    default: null,
  },
  styleObj: {
    type: Object,
    default: () => {},
  },
  rows: {
    type: Number,
    default: 2,
  },
}

export default okTextInputProps
